import React, { useEffect, useState } from "react";
import * as ReactGA from '../../GA';
import outro from '../../assets/outro.mp4'
import history from '../../history';
import "./End.scss";

const End = ({AppStore}) => {

    const [ended, setEnded] = useState(false);

    useEffect(() => {
        ReactGA.pageview(`/end`);
    }, []);

    useEffect(() => {
        if(!ended) {
            return;
        }

        setTimeout(() => {
            history.push('/');
            window.location.reload();
        }, 20000)
    }, [ended])

    if(!ended) {
       return (
           <div className="End">
               <video autoPlay muted playsInline onEnded={() => setEnded(true)}>
                   <source src={outro} type="video/mp4" />
               </video>
           </div>
       );
    }
    
    return (
        <div className="End">
            <div className="Credits">
                <div className="CreditsContent">
                    {AppStore.state.lang === 'it' ? (
                        <>
                            <p>
                                <span>Scrittura - Regia - Produzione - Riprese:</span>{" "}
                                <strong>Fulvio Risuleo</strong>
                            </p>
                            <p>
                                <span>Con:</span>{" "}
                                <strong>
                                    Aurélia Poirier, Yann Coeslier, Antoine Lesimple,
                                    Waly Gironi
                                </strong>
                            </p>
                            <p>
                                <span>Montaggio:</span> <strong>Ilenia Zincone</strong>
                            </p>
                            <p>
                                <span>Post produzione suono:</span>{" "}
                                <strong>Francesco Lucarelli</strong>
                            </p>
                            <p>
                                <span>Correzione colore immagine:</span>{" "}
                                <strong>Juri Fantigrossi</strong>
                            </p>
                            <p>
                                <span>Grafiche:</span> <strong>Frita</strong>
                            </p>
                            <p>
                                <span>Vfx:</span> <strong>Alessio Franco</strong>
                            </p>
                            <p>
                                <span>Musica:</span> <strong>Bebawinigi</strong>
                            </p>
                            <p>
                                <span>Assistenti regia:</span>{" "}
                                <strong>
                                    Giuseppe Volpe, Jean-Brice Royer, Alessandra Pizzo,
                                    Greta Margherita
                                </strong>
                            </p>
                            <p>
                                <span>Grazie a:</span>{" "}
                                <strong>
                                    Giacomo Nanni, Luigi Critone, Andrea Ferraris,
                                    Roland Topor, Andrea Sorini, Virginia Quaranta,
                                    Gianluca Manzetti, Carlo Purpura, Alessandro
                                    Cartosio, Nonna Nina.
                                </strong>
                            </p>
                            <p>Parigi, 2014</p>
                        </>
                    ) : (
                        <>
                            <p>
                                <span>Writing - Direction - Production - Shooting:</span>{" "}
                                <strong>Fulvio Risuleo</strong>
                            </p>
                            <p>
                                <span>With:</span>{" "}
                                <strong>
                                    Aurélia Poirier, Yann Coeslier, Antoine Lesimple,
                                    Waly Gironi
                                </strong>
                            </p>
                            <p>
                                <span>Editing:</span> <strong>Ilenia Zincone</strong>
                            </p>
                            <p>
                                <span>Sound post production:</span>{" "}
                                <strong>Francesco Lucarelli</strong>
                            </p>
                            <p>
                                <span>Image color correction:</span>{" "}
                                <strong>Juri Fantigrossi</strong>
                            </p>
                            <p>
                                <span>Graphics:</span> <strong>Frita</strong>
                            </p>
                            <p>
                                <span>Vfx:</span> <strong>Alessio Franco</strong>
                            </p>
                            <p>
                                <span>Music:</span> <strong>Bebawinigi</strong>
                            </p>
                            <p>
                                <span>Direction assistants:</span>{" "}
                                <strong>
                                    Giuseppe Volpe, Jean-Brice Royer, Alessandra Pizzo,
                                    Greta Margherita
                                </strong>
                            </p>
                            <p>
                                <span>Thanks to:</span>{" "}
                                <strong>
                                    Giacomo Nanni, Luigi Critone, Andrea Ferraris,
                                    Roland Topor, Andrea Sorini, Virginia Quaranta,
                                    Gianluca Manzetti, Carlo Purpura, Alessandro
                                    Cartosio, Nonna Nina.
                                </strong>
                            </p>
                            <p>Paris, 2014</p>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default End;